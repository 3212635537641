var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "content-partner" }, [
    _c("div", { staticClass: "category" }, [
      _vm._v(
        _vm._s(
          _vm.partnerObj[0].replace(/^./, _vm.partnerObj[0][0].toUpperCase())
        )
      ),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.partnerObj[1], function (vertical, key) {
        return _c("li", { key: vertical.public_name + "_" + key }, [
          _vm._v("\n      " + _vm._s(vertical.public_name) + "\n      "),
          vertical.privacy_policy_url
            ? _c("span", [
                _vm._v("\n        | "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: vertical.privacy_policy_url,
                      target: "_blank",
                    },
                  },
                  [_vm._v(" Política de Privacidade ")]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          vertical.terms_and_conditions_url
            ? _c("span", [
                _vm._v("\n        | "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: vertical.terms_and_conditions_url,
                      target: "_blank",
                    },
                  },
                  [_vm._v(" Termos e Condições")]
                ),
              ])
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }